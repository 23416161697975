<div class="login-layout" nz-flex nzJustify="center" nzAlign="center">
  <nz-card class="login-form inner-x-xxs inner-top-xs outer-micro">
    <div class="text-center">
      <img class="pangea-logo" alt="Pangea logo" src="/assets/images/TNP_logo_DARK.png" />
    </div>
    <form nz-form [formGroup]="validateForm" class="inner-y-xxs" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your username!">
          <nz-input-group nzPrefixIcon="user" nzSize="large">
            <input type="text" nz-input formControlName="userName" placeholder="Username" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Password!">
          <nz-input-group nzPrefixIcon="lock" nzSize="large">
            <input type="password" nz-input formControlName="password" placeholder="Password" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Password!">
          <nz-input-group nzPrefixIcon="lock" nzSize="large">
            <input type="password" nz-input formControlName="passwordConfirm" placeholder="Password (confirm)" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <button
        nz-button
        class="outer-top-xxs login-form-button login-form-margin"
        [nzType]="'primary'"
        nzBlock
        nzSize="large"
        nzShape="round">
        Register
      </button>
    </form>
  </nz-card>
</div>
